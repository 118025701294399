<template>
  <div class="container-fluid">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <div class="hello">User List</div>
          </CCol>
          <CCol col="2">
            <CLink
              href="../../assets/samplefiles/user_import_sample.xlsx"
              target="_blank"
              v-show="false"
              id="downloadsample"
              ><CIcon name="cil-arrow-bottom" />Download File</CLink
            >
            <CButton
              block
              color="info"
              onclick="document.getElementById('downloadsample').click()"
            >
              Template</CButton
            >
          </CCol>
          <CCol col="2">
            <vue-excel-xlsx
              class="vue-excel-btn"
              :data="item"
              :columns="columns"
              :file-name="'User List'"
              :file-type="'xlsx'"
              :sheet-name="'User List'"
              >Export Data</vue-excel-xlsx
            >
          </CCol>
          <CCol col="2">
            <CInputFile
              id="importdatafile"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              ref="importfile"
              @change="onFileChange"
              v-show="false"
            />
            <CButton
              block
              color="secondary"
              onclick="document.getElementById('importdatafile').click()"
              >Import Data</CButton
            >
          </CCol>
          <CCol col="2">
            <CButton block router-link :to="{ name: 'Add User' }" color="dark"
              >Add User</CButton
            >
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :hover="true"
          :striped="true"
          :items="item"
          :fields="fields"
          :border="true"
          :column-filter="true"
          :table-filter="true"
          :items-per-page="20"
          :items-per-page-select="true"
          pagination
          sorter
        >
          <template #status="{ item }">
            <td>
              <span>
                <CDropdown
                  size="sm"
                  toggler-text="Action"
                  color="link"
                  class="m-0 d-inline-block"
                >
                  <CDropdownItem
                    :to="{
                      name: 'EditUser',
                      params: { id: item._id },
                    }"
                  >
                    Edit
                  </CDropdownItem>
                  <!-- <CDropdownItem
                    v-if="item.deleted == false"
                    @click="deActivateItem(item._id, item.deleted)"
                  >
                    DeActivate</CDropdownItem>
                 
                  <CDropdownItem
                    v-else
                    @click="deActivateItem(item._id, item.deleted)"
                    >Activate</CDropdownItem
                  > -->
                  <CDropdownItem @click="deleteItem(item._id)"
                    >Delete</CDropdownItem
                  >
                </CDropdown>
              </span>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
import XLSX from "xlsx";
import Store from "@/services/store";

export default {
  name: "list-user",
  data() {
    return {
      columns: [
        {
          label: "First Name",
          field: "firstName",
        },
        {
          label: "Last Name",
          field: "lastName",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "User Type",
          field: "userType",
        },
      ],
      item: [],
      fields: [
        {
          key: "firstName",
          label: "First Name",
          sortable: true,
          sortDirection: "asc",
        },
        {
          key: "lastName",
          label: "Last Name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "status",
          label: "Action",
          sortable: true,
          filter: false,
        },
      ],
      importdata: null,
      listofData: [],
      emails: [],
    };
  },
  created() {
    this.getUserListData();
    document.title = this.$store.state.projecttitle + " - User List";
  },
  methods: {
    getUserListData() {
      APIService.get("/users/userlist").then(
        ((response) => {
          this.$set(this, "item", response.data);
        }).bind(this)
      );
    },
    // deActivateItem(id, deleted) {
    //   var req = {
    //     deleted: !deleted,
    //   };
    //   this.$confirm({
    //     message:
    //       "Are you sure your want to " +
    //       (deleted == false ? "Deactivate" : "Activate") +
    //       " this record?",
    //     button: {
    //       no: "No",
    //       yes: "Yes",
    //     },
    //     callback: (confirm) => {
    //       if (confirm) {
    //         APIService.post("/users/updateuser", req, id).then((response) => {
    //           this.getUserListData();
    //         });
    //       }
    //     },
    //   });
    // },
    deleteItem(id) {
      this.$confirm({
        message: "Are you sure your want to Delete this record?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            APIService.delete("/users/deleteuser/" + id).then((response) => {
              this.getUserListData();
            });
          }
        },
      });
    },
    onFileChange(filename, e) {
      e.stopPropagation();
      e.preventDefault();
      var thisObj = this;
      var files = e.target.files,
        i,
        f;
      Store.commit("showLoader");
      this.emails = [];
      for (i = 0, f = files[i]; i != files.length; ++i) {
        var reader = new FileReader();

        reader.onload = function (e) {
          var results,
            data = e.target.result;
          var fixedData = fixdata(data);
          var workbook = XLSX.read(btoa(fixedData), { type: "base64" });
          var firstSheetName = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[firstSheetName];

          results = XLSX.utils.sheet_to_json(worksheet);
          thisObj.importdata = results;
          if (
            thisObj.importdata != null &&
            thisObj.importdata != undefined &&
            thisObj.importdata.length > 0
          ) {
            for (
              var dataCount = 0;
              dataCount < thisObj.importdata.length;
              dataCount++
            ) {
              var userdata = thisObj.importdata[dataCount];
              if (
                userdata.firstName != undefined &&
                userdata.firstName.toString() != "" &&
                userdata.lastName != undefined &&
                userdata.lastName.toString() != "" &&
                userdata.email != undefined &&
                userdata.email.toString() != "" &&
                userdata.password != undefined &&
                userdata.password.toString() != ""
              ) {
                thisObj.emails.push(userdata.email);
                userdata.userType = "user";
                userdata.deleted = false;
                thisObj.listofData.push(userdata);
              } else {
                thisObj.listofData = null;
                break;
              }
            }
            e.target.files = null;
            thisObj.uploadData();
          }
        };
        reader.readAsArrayBuffer(f);
      }
    },
    uploadData() {
      if (
        this.listofData != null &&
        this.listofData != [] &&
        this.listofData.length > 0
      ) {
        var checkUniqueData = {
          email: this.emails,
        };
        APIService.post("/users/checkuserexist", checkUniqueData).then(
          ((response) => {
            if (
              response.data != null &&
              response.data != undefined &&
              response.data.length == 0
            ) {
              APIService.post("/users/addusermultiple", this.listofData).then(
                () => {
                  document.getElementById("importdatafile").value = null;
                  this.$successAlert("Data imported successfully!");
                  this.getUserListData();
                  Store.commit("hideLoader");
                }
              );
            } else {
              document.getElementById("importdatafile").value = null;
              this.$dangerAlert("Duplicate data found!");
              Store.commit("hideLoader");
            }
          }).bind(this)
        );
      } else {
        document.getElementById("importdatafile").value = null;
        this.$dangerAlert("Please fill all fields in CSV before upload");
        Store.commit("hideLoader");
      }
    },
  },
};

function fixdata(data) {
  var o = "",
    l = 0,
    w = 10240;
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w))
    );
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}
</script>

<style scoped>
.vue-excel-btn {
  outline: none;
  display: block;
  width: 100%;
  color: #fff;
  background-color: #636f83;
  border-color: #636f83;
  font-weight: 400;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}
.illness {
  white-space: break-spaces;
}
</style>
